<template>
  <div>
    <div :class="{header:true, fixed:scrolltop>100}">
      <div class="header-div">
        <!-- <div class="left-logo"> -->
        <div class="logo">
        
        </div>
        <!-- <div class="company">浙江鹿达科技有限公司</div> -->
        <!-- </div> -->
        <!-- <div class="tab-contianer"> -->
        <div style="flex:1">
          <div class="search" @click="goswitch()">
        <div class="name" >EN</div>
        <img src="@/imgs/switch.png" alt="">
        <div class="name" >CN</div>
      </div>
        </div>
        <div class="labels">
          <div v-for="(item, index) in goPageList" :key="index">
            <div class="modular" :class="[pagename == item.id ? 'selected' : '']" @click="goPage(item.id)">{{ item.name }}</div>
          </div>
        </div>

        <div class="search" @click="searchbtn">
          <i class="el-icon-search"></i>
        </div>
        <div class="wechart" >
          <el-popover placement="bottom" width="200" trigger="hover">
            <div class="wechart-div">
              <img width="100%" src="@/imgs/erweima.jpg" alt="" />
              <div class="Follow">关注鹿达</div>
            </div>
            <div class="wechart-img" slot="reference"> </div>
           
          </el-popover>

        </div>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  props: {},
  data() {
    return {
        scrolltop:0,
      input: "",
      dataTime: "0",
      options: [],
      show1: false,
      pagename: "null",
      goPageList: [],
    };
  },
  created() {
    if (this.goPageList == "") {
      this.treeSelect();
    }
    window.addEventListener('scroll', (e) => {

let scrollTop = window.pageYOffset
this.scrolltop=scrollTop


})

  },
  mounted() {},
  watch: {
    $route: {
      handler(newValue) {
        console.log(newValue);
        if (newValue.name === "product") {
          this.pagename = newValue.query.id;
        }
        if (newValue.name === "information") {
          this.pagename = newValue.query.id;
        }
        if (newValue.name === "Integration") {
          this.pagename = newValue.query.id;
        }
        if (newValue.name === "recruitment") {
          this.pagename = 7;
        }
        if (newValue.name === "teamBuilding") {
          this.pagename = 7;
        }
        if (newValue.name === "AboutUs") {
          this.pagename = 10;
        }
        if (newValue.name === "home") {
          this.pagename = 0;
        }
        if (newValue.name === null) {
          this.pagename = 0;
        //   this.$router.push("/");
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    goPage(path) {
      if (this.pagename != path) {
        this.show1 = false;
        this.pagename = path;
        if (path == 0) {
          this.$router.push("/");
        } else if (path == 1) {
          this.$router.push({
            path: "/product",
            query: {
              id: path,
            },
          });
        } else if (path == 5) {
          this.$router.push({
            path: "/information",
            query: {
              id: path,
            },
          });
        } else if (path == 6) {
          this.$router.push({
            path: "/Integration",
            query: {
              id: path,
            },
          });
        } else if (path == 7) {
          this.$router.push({
            path: "/recruitment",
            query: {
              id: 8,
            },
          });
        } else if (path == 10) {
          this.$router.push("/AboutUs");
        }
      }
    },
    btn() {
      this.show1 = true;
    },
    detailsbtn(name) {
      var that = this;
      that.show1 = false;
      if (this.pagename != name) {
        this.pagename = name;
        if (name == 8) {
          this.$router.push({
            path: "/recruitment",
            query: {
              id: name,
            },
          });
        } else {
          this.$router.push({
            path: "/teamBuilding",
            query: {
              id: name,
            },
          });
        }
      }
      console.log(that.show1);
    },
    maskbtn() {
      this.show1 = false;
    },
    // 搜索
    searchbtn() {
      console.log(1111111111111);
      this.$router.push({
        path: "/search",
      });
      // this.$router.push('/search')
      // this.$router.push({ name: 'search' })
    },
    // 一级菜单
    async treeSelect() {
      const res = await fnAxios({
        method: "get",
        url: "/pcApi/treeSelect",
        headers: {
          "Content-Type": "application/json",
        },
      });
      var data = {
        id: 0,
        name: "Home",
      };
      this.goPageList.push(data);
      res.data.data.map((item) => {
        this.goPageList.push(item);
        if (item.id == 7) {
          this.options = item.children;
        }
      });
      console.log(this.goPageList);
    },
    goswitch() {
      // if(this.currentLanguage === 'EN'){
      //   this.currentLanguage = 'CN';
      // }else{
      //   this.currentLanguage = 'CN';
      // }
      window.location.href = 'https://www.luda-tec.com'
    },
  },
};
</script>

<style scoped  lang="less">

.Follow{
    text-align: center;
}
.header-div .logo {
  margin-left: 30px;
  height: 60px;
  width: 200px;
  background-image: url("@/imgs/logo1.png");
  background-size: 200px;
  image-rendering:-moz-crisp-edges;
	image-rendering:-o-crisp-edges;
	image-rendering:-webkit-optimize-contrast;
	image-rendering: crisp-edges;
	-ms-interpolation-mode:nearest-neighbor;
}
.header.fixed .logo {
  margin-left: 30px;
  height: 50px;
  width:160px;
  background-image: url("@/imgs/logo.png");
  background-size: 160px;
  background-repeat: no-repeat;
  background-position:center center;
  image-rendering:-moz-crisp-edges;
	image-rendering:-o-crisp-edges;
	image-rendering:-webkit-optimize-contrast;
	image-rendering: crisp-edges;
	-ms-interpolation-mode:nearest-neighbor;
}


.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 9999;
  color: #fff;
  transition: all .5s;
  -webkit-transition: all .5s;
 .wechart-img {
  width: 20px;
  height: 20px;
  background-image: url("@/imgs/wechart.png") ;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size:20px;
  image-rendering:-moz-crisp-edges;
	image-rendering:-o-crisp-edges;
	image-rendering:-webkit-optimize-contrast;
	image-rendering: crisp-edges;
	-ms-interpolation-mode:nearest-neighbor;

}
}
.header.fixed {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;

  width: 100%;
  height: 60px;

  color: #222;
  background: #fff;
  box-shadow:0px 2px 10px rgba(0, 0, 0, 0.05);
  .wechart-img {
  width: 20px;
  height: 20px;
  background-image: url("@/imgs/wechart1.png") ;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size:20px;
  image-rendering:-moz-crisp-edges;
	image-rendering:-o-crisp-edges;
	image-rendering:-webkit-optimize-contrast;
	image-rendering: crisp-edges;
	-ms-interpolation-mode:nearest-neighbor;
}
}
.header.fixed .header-div{
    height: 60px;
}
.header-div .search {
  margin-left: 10px;
  margin-right: 20px;
  display: flex;
  cursor: pointer;
  z-index: 3;
}

.wechart{
    margin-right: 40px;
}
.search i {
  
  font-size: 24px;
}
.header-div {
  display: flex;
  align-items: center;
  height: 70px;
}





.header-div .labels {
  margin-left: 100px;
  margin-right: 50px;
  width: 500px;
  display: flex;
  justify-content: space-between;
}

.header-div .modular {
  position: relative;
  padding: 0 5px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  cursor: pointer;
 
}


.header.fixed .modular.selected {
    content: '';
    height: 2px;
    background: #0265cb;
   color:#0265cb ;
}
.header.fixed  .modular:hover {
    color:#0265cb ;
 
}
.header.fixed  .modular:before {
    content: '';
    width: 0%;
    height: 2px;
    background: #0265cb;
    position: absolute;
    left: 0;
    top: -2;
    transition: all .3s;
    -webkit-transition: all .3s;
}

.header .modular.selected {
    content: '';
    height: 2px;
    background: #fff700;
   color:#fff700 ;
}
.header .modular:hover {
    color:#fff700 ;
 
}
.header .modular:before {
    content: '';
    width: 0%;
    height: 2px;
    background: #fff700;
    position: absolute;
    left: 0;
    top: -2;
    transition: all .3s;
    -webkit-transition: all .3s;
}


.labels .modular:hover:before {
    width: 100%;
  
}



.slelct .details:hover {
  background: #e5e1e1;
}
.search {
      display: flex;
      align-items: center;
      margin-right: 30px;
      cursor: pointer;

      img {
        width: 20px;
        height: 20px;
      }

      .name {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
      }
    }

</style>